import React from 'react'

import { googleAnalyticsEvent, segmentEvent} from '../../../helperFunctions'

const CanvaPage = () => {

    const clickHandler = (event) => {
        const name = event.target.name
        googleAnalyticsEvent(name)
        segmentEvent(name)
    }
    
    return (
        <div className="positionReadingPage">
        <div className="widthReadingPage marginParagraphReadingPage paragraphSeparatorReadingPage">
            <h1>Hey <span className="bold companyNameReadingPage">Canva</span>, I'm Pedro.</h1>
            <p>I currently manage product <a className="underline bold" onClick={clickHandler} name="LodgifyCanva" href="https://www.lodgify.com" target="_blank" rel="noreferrer">@Lodgify</a> and live in <span className="bold highlightReadingPage"> Barcelona.<span className="highlightStarReadingPage">  🇪🇸 </span></span></p>
            <p>I am a big fan of tools that enable people to build. I truly find joy in crafting and I believe most people do as well.</p>
            <p>Somehow <span className="bold companyNameReadingPage">Canva</span> found a way to help people do that. <span className="quoteReadingPage">I want to be part of it.</span></p>
            <p>I thought that instead of only sending you my <a className="underline bold" href="https://drive.google.com/file/d/1wAPcoOqOWkpne-dPSs4s8D40FThe92n8/view?usp=sharing" target="_blank" rel="noreferrer" onClick={clickHandler} name="CVLinkCanva">CV</a>, I would share this 3min reading to show you how interested I am in joining you.</p>

            <h3>. . .</h3>

            <p>A few days ago I read “In 2019 there were over 2 billion desings made in <span className="bold companyNameReadingPage">Canva</span>”. This fact made me remember of a job I got in 2014 with a CV made using your tool (I can show you that if we end up having a call). My immediate "product-brain" thought was “How many of those 2B were CVs? Do they measure how many jobs they may have directly impacted?” Looking forward to find out.</p>
            <p>Another fact I've been thinking about is that even though we have a strong design team in Lodgify, our commercial team uses <span className="bold companyNameReadingPage">Canva</span> to assemble content. You can find designs made with your product in many of our ebooks, templates and posts. This makes me imagine that a fair share of your 30M users are businesses like us, using your product to increase their impact.</p>
            <p className="bold highlightReadingPage">That is true value.<span className="highlightStarReadingPage"> 👏 </span></p>
            <p>And being able to impact in this level, is why I want to join you.</p>

            <h3>. . .</h3>

            <p>Now, let me tell you a bit about me.</p>   
            <p>Throughout the last 6 years I’ve dedicated most of my time to building products.</p>
            <p>For the last 3, I’ve helped scale Lodgify 11x. Among many achievements (and failures), me and the teams I worked with: </p>
            <ul className="marginListReadingPage">
                <li>Integrated with a 3rd party company which led to the development of a whole new module in Lodgify. Today, +30% of all new customers buy this module. It corresponds to +40% of all new MRR.</li>
                <li>Drove the implementation of a set of booking management and payment related features which today account for around 20% of our monthly revenue. </li>
                <li>Throughout the last year alone, ran over 12 different experiments related with feature mix vs pricing vs plan mix to test retention, adoption and conversion elasticity.</li>
            </ul>

            <p>The development, data, design and business skills I've acquired make me feel I became a strong product leader. Nevertheless, I am still as hungry and humble as if it was day 1, and excited about what's ahead.</p>

            <p>I promissed to do it in 3min, therefore I'll stop here. In case after reading the above you think I could be a fit for what <span className="bold companyNameReadingPage">Canva</span> is looking for, <span className="quoteReadingPage">I’ve shared my contact details</span> <a className="underline bold" onClick={clickHandler} name="CanvaPageCanvaNote" href="https://www.canva.com/design/DAEVABMzFJY/hcKfdRopm451sJTHfEmhfw/view?utm_content=DAEVABMzFJY&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton" target="_blank" rel="noreferrer">in this Canva “note”</a>.</p>
            <p>I’ll also leave some links below so you can get to know me better: </p>
            <ul>
                <li><a onClick={clickHandler} name="CanvaPageCanvaExperienceLink" href="https://www.pedroblumenschein.com/experience" target="_blank" rel="noreferrer">Experience 🥋</a></li>
                <li><a onClick={clickHandler} name="CanvaPageCanvaEducationLink" href="https://www.pedroblumenschein.com/education" target="_blank" rel="noreferrer">Education 📚</a></li>
                <li><a href="https://www.linkedin.com/in/pedroblumenschein/" target="_blank" rel="noreferrer" onClick={clickHandler} name="canvaPageLinkedInLink">LinkedIn 🌐</a></li>
            </ul>
            <p>Hope to hear from you soon :)</p>
            <p>Pedro.</p>
            <br/>

            <p>Note: This page is not accessible via the normal flow of the website. To access it again one can do so via www.pedroblumenschein.com/canva. This page will be live until 31/03/2021.</p>

        </div>
        </div>
    )
}

export default CanvaPage;