import ReactGA from 'react-ga'

export const googleAnalyticsPage = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export const googleAnalyticsEvent = (name) => {
    ReactGA.event({
        category: 'Link_Click',
        action: `${name}`
    })
}

export const segmentPage = () => {
    window.analytics.page(window.location.pathname);
}

export const segmentEvent = (name) => {
    window.analytics.track('Link_Click', {
        name: name
      });
}

