import React from 'react'

import EducationItem from '../../EducationItem/EducationItem'
import MorePage from '../MorePage/MorePage'

import DataJSON from '../../../data.json'

import './EducationPage.css'



const EducationPage = () => {


    const educationListBuilder = () => {
        return DataJSON.education.map( (item) => {
            return <EducationItem key={item.id} {...item}/>
        })
    }

    return (
        <div className="educationPageContainer">
            <h1>My Education</h1>
            {educationListBuilder()}
            <div>
                <MorePage/>
            </div>
        </div>
    )
}

export default EducationPage;