import React from 'react'
import './EducationItem.css'

const EducationItem = (props) => {

    const {id, institution, title, dateStart, dateEnd, city, description, diploma} = props
    return (
        <div className="educationItemContainer itemContainerMargin">
            <h3 className="itemTitle">{institution} - <span className="title">{title}</span></h3>
            <h4 className="datesPeriod">{dateStart} - {dateEnd}</h4>
            <p>{description}</p>
            <h5>{city}</h5>
        </div>
    )
}

export default EducationItem;
