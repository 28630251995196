import React from 'react'

import { googleAnalyticsEvent, segmentEvent} from '../../../helperFunctions'

const AtlassianPage = () => {

    const clickHandler = (event) => {
        const name = event.target.name
        googleAnalyticsEvent(name)
        segmentEvent(name)
    }

    const companyStyleClass = ' bold atlassianNameReadingPage'
    
    return (
        <div className="positionReadingPage">
        <div className="widthReadingPage marginParagraphReadingPage paragraphSeparatorReadingPage">
            <h1>Hey <span className={companyStyleClass}>Atlassian</span>, I'm Pedro.</h1>
            <p>I currently manage product <a className="underline bold" onClick={clickHandler} name="LodgifyAtlassian" href="https://www.lodgify.com" target="_blank" rel="noreferrer">@Lodgify</a> and live in <span className="bold highlightReadingPage"> Barcelona.<span className="highlightStarReadingPage">  🇪🇸 </span></span></p>
            <p>I am a big fan of tools that enable people to build. I truly find joy in crafting and I believe most people do as well.</p>
            <p><span className={companyStyleClass}>Atlassian</span> found many ways to help people do that. <span className="quoteReadingPage">I want to be part of it.</span></p>
            <p>I thought that instead of only sending you my <a className="underline bold" href="https://drive.google.com/file/d/1wAPcoOqOWkpne-dPSs4s8D40FThe92n8/view?usp=sharing" target="_blank" rel="noreferrer" onClick={clickHandler} name="CVLinkAtlassian">CV</a>, I would share this 3min reading to show you how interested I am in joining you.</p>

            <h3>. . .</h3>

            <p>Organizations use <span className={companyStyleClass}>Atlassian's</span> ecosystem to build things that resolve some of the biggest problems out there.</p>

            <p>People use trello to track their work “todos”, but also to plan their weekend trip. Teams use Jira everyday to vision, plan, manage and ship great software. They use confluence to externalize their knowledge and to co-work on ideas. Developers use Bitbucket and Bamboo to collaborate, store, maintain and release code.</p>

            <p>There are people out there using <span className={companyStyleClass}>Atlassian's</span> products to <span className="quoteReadingPage">send rockets to space!</span></p>

            <p className="bold highlightReadingPage">That is true value.<span className="highlightStarReadingPage"> 👏 </span></p>

            <p>Having this level of influence over what is being achieved out there, is the core reason why I want to join you.</p>
            
            <h3>. . .</h3>

            <p>Now, let me tell you a bit about me.</p>   
            <p>Throughout the last 6 years I’ve dedicated most of my time to building products.</p>
            <p>For the last 3, I’ve helped scale Lodgify 11x. Among many achievements (and failures), me and the teams I worked with: </p>
            <ul className="marginListReadingPage">
                <li>Integrated with a 3rd party company which led to the development of a whole new module in Lodgify. Today, +30% of all new customers buy this module. It corresponds to +40% of all new MRR.</li>
                <li>Drove the implementation of a set of booking management and payment related features which today account for around 20% of our gross MRR. </li>
                <li>Throughout the last year alone, ran over 12 different experiments related with feature mix vs pricing vs plan mix to test retention, adoption and conversion elasticity.</li>
                <li>Partnered with people from all company levels to deliver products tailored to SMB and SME customers.</li>
            </ul>

            <p>The development, data, design and business skills I've acquired make me feel I became a strong product leader. Nevertheless, I am still as hungry and humble as if it was day 1, and excited about what's ahead.</p>

            <p>I promised to do it in 3min, therefore I'll stop here. In case after reading the above you think I could be a fit for what <span className={companyStyleClass}>Atlassian</span> is looking for, <span className="quoteReadingPage">I’ve shared my contact details</span> <a className="underline bold" onClick={clickHandler} name="AtlassianPagePitchNote" href="https://pedroblumenschein.atlassian.net/l/c/HLsxcS12" target="_blank" rel="noreferrer">in this Confluence "page"</a>.</p>
            <p>I’ll also leave some links below so you can get to know me better: </p>
            <ul>
                <li><a onClick={clickHandler} name="AtlassianPageAtlassianExperienceLink" href="https://www.pedroblumenschein.com/experience" target="_blank" rel="noreferrer">Experience 🥋</a></li>
                <li><a onClick={clickHandler} name="AtlassianPageAtlassianEducationLink" href="https://www.pedroblumenschein.com/education" target="_blank" rel="noreferrer">Education 📚</a></li>
                <li><a href="https://www.linkedin.com/in/pedroblumenschein/" target="_blank" rel="noreferrer" onClick={clickHandler} name="AtlassianPageLinkedInLink">LinkedIn 🌐</a></li>
            </ul>
            <p>Hope to hear from you soon :)</p>
            <p>Pedro.</p>
            <br/>

            <p>Note: This page is not accessible via the normal flow of the website. To access it again one can do so via www.pedroblumenschein.com/atlassian. This page will be live until 31/04/2021.</p>

        </div>
        </div>
    )
}

export default AtlassianPage;