import React from 'react'

import { googleAnalyticsEvent, segmentEvent} from '../../helperFunctions'

const ListItem = (props) => {

    const {title, description, link} = props

    const clickHandler = (event) => {
        const name = event.target.name
        googleAnalyticsEvent(name)
        segmentEvent(name)
    }

    return (
        <li>{title} - <span>
                {
                    link ? <a className="link" href={link} target="_blank" rel="noreferrer" onClick={clickHandler} name={title}>{description}</a>: description
                }
            </span>
        </li>
    )
}

export default ListItem;
