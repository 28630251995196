import React from 'react'

import './Navbar.css'

import { googleAnalyticsEvent, segmentEvent} from '../../helperFunctions'

const Navbar = (props) => {

    const {isMenuOpen, setIsMenuOpen} = props

    const openCloseHandler = (event) => {
        event.preventDefault()
        setIsMenuOpen(!isMenuOpen)
    }

    const clickHandler = (event) => {
        const name = event.target.name
        googleAnalyticsEvent(name)
        segmentEvent(name)
    }


    return (
        <div className="NavbarGlobalContainer">
        
            <div className="NavBarMenusAlignment">

                <div className="NavBarMenuContainers mainMenuItems">
                    <a className="showOnResponsive" href="/" onClick={clickHandler} name="Home">🧠</a>
                    <a className="hideOnResponsive" href="/experience" onClick={clickHandler} name="Experience">Experience</a>
                    <a className="hideOnResponsive" href="/education" onClick={clickHandler} name="Education">Education</a>
                    <a className="hideOnResponsive" href="/projects" onClick={clickHandler} name="Projects">Projects</a>
                </div>

                <div className="NavBarMenuContainers menuitem externalLinks">
                    <a className="hideOnResponsive" href="https://drive.google.com/file/d/1wAPcoOqOWkpne-dPSs4s8D40FThe92n8/view?usp=sharing" target="_blank" rel="noreferrer" onClick={clickHandler} name="Resume">Resume</a>
                    <a className="hideOnResponsive" href="https://www.linkedin.com/in/pedroblumenschein/" target="_blank" rel="noreferrer" onClick={clickHandler} name="LinkedIn">LinkedIn</a>
                    <a className="hideOnResponsive" href="https://github.com/PedroBlumenschein" target="_blank" rel="noreferrer" onClick={clickHandler} name="Github">Github</a>
                </div>

                <a href="/" className="menu" onClick={openCloseHandler}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </a>

            </div>
            
        </div>
    )
}

export default Navbar
