import React from 'react'

import './HomePage.css'

import { googleAnalyticsEvent, segmentEvent} from '../../../helperFunctions'

const HomePage = () => {

    const clickHandler = (event) => {
        const name = event.target.name
        googleAnalyticsEvent(name)
        segmentEvent(name)
    }


    return (
        <div className="homePageContainer">
            <h1>Hey, I'm Pedro.</h1>
            <br/>
            <p>I currently manage product <a onClick={clickHandler} name="Lodgify" href="https://www.lodgify.com" target="_blank" rel="noreferrer">@Lodgify</a> and live in Barcelona.</p>
            <br/>
            <br/>
            <p>Nowadays, I spend most of my time coding, running, dog walking, and thinking about how to leverage a business through product.</p>
            <br/>
            <h4>Here's a list of quick links so you can get to know me better:</h4>
    
            <ul>
                <li><a href="/experience" onClick={clickHandler} name="Experience">Experience 🥋</a></li>
                <li><a href="/education" onClick={clickHandler} name="Education">Education 📚</a></li>
                <li><a href="/projects" onClick={clickHandler} name="Projects">Projects 🧪</a></li>
            </ul>
            
        </div>
    )
}


export default HomePage;