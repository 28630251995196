import React from 'react'
import './SidebarMenu.css'

import { googleAnalyticsEvent, segmentEvent} from '../../helperFunctions'

const SidebarMenu = (props) => {

    const {isMenuOpen} = props

    const clickHandler = (event) => {
        const name = event.target.name
        googleAnalyticsEvent(name)
        segmentEvent(name)
    }

    if (isMenuOpen) {
        return (
            <div className="sidebarMenuContainer">
                <div className="sidebarMenu">
                    <div className="sidebarMenuItemsContainer">
                        <a href="/" onClick={clickHandler} name="Home">Home</a>
                        <a href="/experience" onClick={clickHandler} name="Experience">Experience</a>
                        <a href="/education" onClick={clickHandler} name="Education">Education</a>
                        <a href="/projects" onClick={clickHandler} name="Projects">Projects</a>
                        <a href="https://drive.google.com/file/d/1wAPcoOqOWkpne-dPSs4s8D40FThe92n8/view?usp=sharing" target="_blank" rel="noreferrer" onClick={clickHandler} name="Resume">Resume</a>
                        <a href="https://www.linkedin.com/in/pedroblumenschein/" target="_blank" rel="noreferrer" onClick={clickHandler} name="LinkedIn">LinkedIn</a>
                        <a href="https://github.com/PedroBlumenschein" target="_blank" rel="noreferrer" onClick={clickHandler} name="Github">Github</a>  
                    </div>
                </div>
            </div>
        )
    }
    else {
        return null
    }
    
}

export default SidebarMenu;
