import React from 'react'
import './ExperienceItem.css'

import { googleAnalyticsEvent, segmentEvent} from '../../helperFunctions'

const ExperienceItem = (props) => {

    const {id, company, company_website, role, dateStart, dateEnd, city, accomplishments} = props

    const accomplishmentsBuilder = () => {
        return accomplishments.map( (accomplishment) => {
            return <li key={accomplishment}>{accomplishment} </li>
        })
    }

    const clickHandler = (event) => {
        const name = event.target.name
        googleAnalyticsEvent(name)
        segmentEvent(name)
    }

    return (
        <div className="experienceItemContainer">
            <h3 className="itemTitle"> { company_website ? <a href={company_website} target="_blank"  rel="noreferrer" className="company link" onClick={clickHandler} name={company}>{company}</a> : company} - <span className="role">{role}</span></h3>
            <h4 className="datesPeriod">{dateStart} - {dateEnd}</h4>
            <h5>{city}</h5>
            <ul className="accomplishmentsListContainer">
                {accomplishmentsBuilder()}
            </ul>
            
        </div>
    )
}

export default ExperienceItem;
