import React, {useState, useEffect} from 'react'

import './App.css';
import {Route, Switch } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar'
import SidebarMenu from './components/SidebarMenu/SidebarMenu'

import HomePage from './components/pages/HomePage/HomePage'
import ExperiencePage from './components/pages/ExperiencePage/ExperiencePage'
import EducationPage from './components/pages/EducationPage/EducationPage'
import ProjectsPage from './components/pages/ProjectsPage/ProjectsPage'
import CanvaPage from './components/pages/CanvaPage/CanvaPage'
import AtlassianPage from './components/pages/AtlassianPage/AtlassianPage'

import {googleAnalyticsPage, segmentPage} from './helperFunctions'

import ReactGA from 'react-ga';
ReactGA.initialize('UA-186739431-1');

function App() {

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  console.log(`


    #########   ###         ##
    ###   ###   ###     ##   ##
    ###   ###                 ##
    ###   ###   ###           ##
    ###   ###   ###     ##   ##
    #########   ###         ##

    "Oi" === "Hi" in portuguese. 


    ------- Thanks for coming! if you are in the console, it means you are at least a bit interested in me. Thanks for that! 💙 

    ------- Hit me at pedroblumenschein@gmail.com, it would be great to chat 🙂 

    `)

  useEffect(() => {
    googleAnalyticsPage()
    segmentPage()
  });
  

  return (

    
    <div className="globalContainer">
      <SidebarMenu isMenuOpen={isMenuOpen}/>
      <Navbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
     

     <Switch >
        <Route exact path="/" component={HomePage}/>
        <Route exact path="/experience" component={ExperiencePage}/>
        <Route exact path="/education" component={EducationPage}/>
        <Route exact path="/projects" component={ProjectsPage}/>
        <Route exact path="/canva" component={CanvaPage}/>
        <Route exact path="/atlassian" component={AtlassianPage}/>
      </Switch>
      

    </div>
    
  );
}

export default App;
