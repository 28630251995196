import React from 'react'

import './ExperiencePage.css'
import ExperienceItem from '../../ExperienceItem/ExperienceItem'

import DataJSON from '../../../data.json'


const ExperiencePage = () => {

    const workListBuilder = () => {
        return DataJSON.experience.map( (item) => {
            return <ExperienceItem key={item.id} {...item} />
        })
    }


    
    return (
        <div className="experiencePageContainer">
            <h1>My Experience</h1>
            {workListBuilder()}
        </div>
    )
}

export default ExperiencePage;
