import React from 'react'

import DataJSON from '../../../data.json'
import AccomplishmentItem from '../../AccomplishmentItem/AccomplishmentItem'
import ListItem from '../../ListItem/ListItem'


const MorePage = () => {

    const accomplishmentsListBuilder = () => {
        return DataJSON.other_accomplishments.map( (item) => {
            return (
                <AccomplishmentItem key={item.id} {...item}/>
            )
        })
    }

    const languagesListBuilder = () => {
        return DataJSON.languages.map( (item) => {
            return (
                <ListItem key={item.id} title={item.language} description={item.level} link={item.link}/>
            )
        })
    }

    const coursesListBuilder = () => {
        return DataJSON.courses.map( (item) => {
            return <ListItem key={item.id} title={item.institution} description={item.title} link={item.diploma}/>
        })
    }

    return (
        <div>
            <div className="accomplishmentsContainer sectionContainerMargin">
                <h1>My Certifications</h1>
                {accomplishmentsListBuilder()}

            </div>
            <div className="languagesContainer sectionContainerMargin">
                <h1>Languages I Speak</h1>
                <ul>
                 {languagesListBuilder()}
                </ul>
                
            </div>
            <div className="coursesContainer sectionContainerMargin">
                <h1>Other Courses</h1>
                <ul>
                    {coursesListBuilder()}
                </ul>
            </div>
        </div>
    )
}

export default MorePage;
