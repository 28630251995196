import React from 'react'

import './AccomplishmentItem.css'

import { googleAnalyticsEvent, segmentEvent} from '../../helperFunctions'

const AccomplishmentItem = (props) => {

    const {id, title, institution, description, date, link} = props

    const clickHandler = (event) => {
        const name = event.target.name
        googleAnalyticsEvent(name)
        segmentEvent(name)
    }

    return (
        <div className="accomplishmentItemContainer itemContainerMargin">
            <h3 className="itemTitle">{institution} - <span className="title">{ link ? <a className="link" href={link} target="_blank" rel="noreferrer" onClick={clickHandler} name={title}>{title}</a> : title}</span></h3>
            <h4 className="datesPeriod">{date}</h4>
            <p>{description}</p>
        </div>
    )
}

export default AccomplishmentItem; 
