import React from 'react'
import './ProjectsPage.css'

const ProjectsPage = () => {
    return (
        <div>
            <h1>My Projects</h1>
            <h3>No projects yet.</h3>
            <p>Or at least no public ones 😏.</p>
        </div>
    )
}

export default ProjectsPage;
